<template>
	<v-container class="ma-0 background-transparent">
		<v-container class="ma-0 mb-8">
			<AdminDefaultSubheader class="mb-10">Plans</AdminDefaultSubheader>
			<p class="primary-text">My current plan: PLAN PRO ULTRA +</p>
			<v-btn
				:disabled="true"
				class="admin-primary-button secondary-contrast-background"
				>Change Plan</v-btn
			>
		</v-container>
		<v-container class="ma-0 mb-8">
			<AdminDefaultSubheader class="mb-10 mt-16"
				>Payment Method</AdminDefaultSubheader
			>
			<p class="primary-text">Credit Card#:</p>
			<p class="primary-text">Name:</p>
			<p class="primary-text">Expired Date:</p>
			<v-btn
				:disabled="true"
				class="admin-primary-button secondary-contrast-background"
				>Change Payment Method</v-btn
			> </v-container
		><v-container class="ma-0 mb-8">
			<AdminDefaultSubheader class="mb-10 mt-16"
				>Billing History</AdminDefaultSubheader
			>
		</v-container>
	</v-container>
</template>

<script>
import AdminDefaultSubheader from "../../../components/AdminDefaultSubheader.vue";
export default {
	name: "OrganizationSettingsBilling",
	components: { AdminDefaultSubheader },
	data() {
		return {
			colors: ["#000000", "#ABCDEF", "#123456", "#FFFFFF"]
		};
	}
};
</script>

<style></style>
